import { Splide } from '@splidejs/splide';

export const INIT_sliders = () => {
    const sliders = [...document.getElementsByClassName('js-slider')];

    if (sliders) {
        sliders.forEach((item) => {

            // Gallery Carousel
            if (item.classList.contains('gallery-carousel-slider')) {
                const arrowPrev = item.dataset.prev;
                const arrowNext = item.dataset.next;

                let slider = new Splide(item.querySelector('.gallery-carousel-slider--splide'), {
                    rewind: true,
                    arrows: false,
                    pagination: true,
                    // focus: 0,
                    perPage: 3,
                    gap: '24px',
                    breakpoints: {
                        769: {
                            perPage: 1,
                        },
                        1025: {
                            perPage: 2,
                        },
                    },
                });

                slider.on('overflow', (isOverflow) => {
                    if (isOverflow) {
                        document.getElementById(arrowPrev).disabled = false;
                        document.getElementById(arrowNext).disabled = false;
                    } else {
                        document.getElementById(arrowPrev).disabled = true;
                        document.getElementById(arrowNext).disabled = true;
                    }
                });

                slider.mount();

                window.addEventListener('click', (event) => {
                    // event.target.blur();

                    if (event.target.id === arrowPrev) {
                        slider.go('<');
                    }

                    if (event.target.id === arrowNext) {
                        slider.go('>');
                    }
                });
            }

            // Gallery Modal
            if (item.classList.contains('gallery-modal')) {
                const arrowPrev = item.dataset.prev;
                const arrowNext = item.dataset.next;

                let slider = new Splide(item.querySelector('.gallery-modal--splide'), {
                    type: 'loop',
                    arrows: false,
                    pagination: false,
                });

                slider.mount();

                window.addEventListener('click', (event) => {
                    const target = event.target;

                    if (target.id === arrowPrev) {
                        slider.go("<");
                    }

                    if (target.id === arrowNext) {
                        slider.go(">");
                    }

                    if (target.dataset.splideGoto) {
                        slider.go(parseInt(target.dataset.splideGoto));
                    }
                });
            }
        });
    }
};
